const FIREBASE_CONFIG = {
    apiKey: "AIzaSyBUrdCgC6DNwvz22X130fPlcTkRhnOTmXk",
    authDomain: "thecrypt-1c8b5.firebaseapp.com",
    databaseURL: "https://thecrypt-1c8b5-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "thecrypt-1c8b5",
    storageBucket: "thecrypt-1c8b5.appspot.com",
    messagingSenderId: "1086062473802",
    appId: "1:1086062473802:web:af3c9ad4942ac092f8ecb3",
    measurementId: "G-7SL8Q7VZ3C"
}

export default FIREBASE_CONFIG