import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore'
import firebaseApp from '../utils/firebase'
import SendBird from '../utils/sendbird'
import { toast } from 'react-toastify'
import { getDatabase, ref, onValue, update, onDisconnect, set } from 'firebase/database'
import throttle from "lodash.throttle"

const db = getFirestore(firebaseApp)
const realtimeDb = getDatabase(firebaseApp)

export const getRoom = async key => {
  const roomQuery = await getDoc(doc(db, 'groups', key))
  const { value } = roomQuery.data()
  const sb = await SendBird()
  return await sb.fetchRoomById(value)
}

export const createGroup = createAsyncThunk('groups/create', async (roomKey, { dispatch }) => {
  const sb = await SendBird()
  const group = await sb.createRoom({ roomType: sb.RoomType.LARGE_ROOM_FOR_AUDIO_ONLY })
  await setDoc(doc(db, 'groups', roomKey), { value: group.roomId })
  return group.roomId
})

export const updateGroup = createAsyncThunk('groups/update', async (value, { getState, dispatch }) => {
  const { groups } = getState()
  const room = await getRoom(groups.group)
  return room.participants.map(({ clientId, user, isAudioEnabled }) => ({ clientId, user: { userId: user.userId, nickname: user.nickname}, isAudioEnabled}))
})

const addPlayer = (engine, room, participant) => {
  const playerStateRef = ref(realtimeDb, `groups/${room}/${participant.user.userId}`)

  // Listen for state updates to this new participant
  const unsubscribePlayerState = onValue(playerStateRef, doc => {
    if (doc.exists()) {
      const state = doc.val()
      engine.fire(`player:update:${participant.user.userId}`, doc => doc.val())
      engine.fire('player:update', { id: participant.user.userId, state })
    }
  })

  // Notify the engine of the new participant
  engine.fire('player:add', { id: participant.user.userId, username: participant.user.nickname })

  // If we recieve an remove event then unsubscribe from states updates
  engine.once(`player:remove:${participant.user.userId}`, _ => unsubscribePlayerState())
}

const removePlayer = (engine, participant) => {
  engine.fire(`player:remove:${participant.user.userId}`)
  engine.fire('player:remove', { id: participant.user.userId })
}

export const joinGroup = createAsyncThunk('groups/join', async (id, { getState, dispatch }) => {
  const { auth, groups } = getState()

  // Hack - need a ref to engine, but can't pass as a param because it kills redux
  const engine = pc.Application.getApplication()

  // leave if already in a group
  if (groups.group) await dispatch(leaveGroup(engine))

  if (window && process.env.NODE_ENV === 'production' ) window.onbeforeunload = async _ => await dispatch(leaveGroup(engine))

  const room = await getRoom(id)

  // Calls
  await room.enter({ audioEnabled: true })
  const audioView = new window.Audio()
  audioView.autoplay = true
  room.setAudioForLargeRoom(audioView)

  // Mute room audio
  if (engine && engine.systems) engine.systems.sound.volume = 0

  // Multiplayer - On entity update, broadcast the participant state
  const meStateRef = ref(realtimeDb, `groups/${id}/${auth.user.uid}`)
  const throttledUpdate = throttle(updates => update(meStateRef, updates), 1000 / 10)

  engine.on('player:broadcast', throttledUpdate)

  // On disconnect remove the state from the db
  onDisconnect(meStateRef).remove()  

  room.on('remoteParticipantEntered', async participant => {
    addPlayer(engine, id, participant)

    toast(`${participant.user.nickname} joined your group`)
    await dispatch(updateGroup())
  })

  room.on('remoteParticipantExited', async participant => {
    removePlayer(engine, participant)
    toast(`${participant.user.nickname} left your group`)
    await dispatch(updateGroup())
  })

  room.on('remoteAudioSettingsChanged', async _ => await dispatch(updateGroup()))

  // Notify that you've joined
  toast("🚀 You've joined the group!")

  // Create serializable state
  const participants = room.participants.map(({ clientId, user, isAudioEnabled }) => ({ clientId, user: { userId: user.userId, nickname: user.nickname}, isAudioEnabled}))

  // Notify of any pariticpants already present in the room
  participants
    .filter(participant => participant.user.userId !== auth.user.uid) // not myself
    .forEach(participant => addPlayer(engine, id, participant))

  return { group: id, participants }
})

export const toggleMicrophone = createAsyncThunk('groups/toggleMicrophone', async (value, { dispatch, getState }) => {
  const { groups } = getState()
  const room = await getRoom(groups.group)
  if (room.localParticipant) room.localParticipant.isAudioEnabled ? room.localParticipant.muteMicrophone() : room.localParticipant.unmuteMicrophone()
  const { participants } = await getRoom(groups.group)
  const safeParticipants = participants.map(({ clientId, user, isAudioEnabled }) => ({ clientId, user: { userId: user.userId, nickname: user.nickname}, isAudioEnabled}))
  return { isAudioEnabled: room.localParticipant.isAudioEnabled, participants: safeParticipants }
})

export const leaveGroup = createAsyncThunk('groups/leave', async (value, { dispatch, getState }) => {
  const { auth, groups } = getState()

  if (!groups.group) return

  if (window && process.env.NODE_ENV === 'production' ) window.onbeforeunload = null

  // Exit Room
  const room = await getRoom(groups.group)
  room.removeAllEventListeners()
  await room.exit()

  // Hack - need a ref to engine, but can't pass as a param because it kills redux
  const engine = window.pc.Application.getApplication()

  // Unmute audio
  if (engine && engine.systems) engine.systems.sound.volume = 1

  // remove multiplayer listeners
  engine.off('player:broadcast')

  groups.participants.forEach(participant => removePlayer(engine, participant))

  // delete my remote data
  set(ref(realtimeDb, `groups/${groups.group}/${auth.user.uid}`), null)

  return groups.group
})

export const groupsSlice = createSlice({
  name: 'groups',
  initialState: {
    group: null,
    participants: [],
    isAudioEnabled: true
  },
  reducers: {
  },
  extraReducers: {
    [updateGroup.fulfilled]: (state, action) => {
      state.participants = action.payload
    },
    [toggleMicrophone.fulfilled]: (state, action) => {
      const { isAudioEnabled, participants } = action.payload
      state.isAudioEnabled = isAudioEnabled
      state.participants = participants
    },
    [joinGroup.fulfilled]: (state, action) => {
      const { group, participants } = action.payload
      state.group = group
      state.participants = participants
    },
    [leaveGroup.fulfilled]: (state, action) => {
      state.group = null
      state.participants = []
    }
  }
})
export default groupsSlice.reducer
